<template>
  <div class="ledgerErrorLog">
    <el-dialog
      title="分账错误记录"
      width="600px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <div class="log-table">
        <el-table
          :data="tableData"
          header-cell-class-name="table_header"
          row-key="id"
          border
          max-height="500"
        >
          <el-table-column width="180px"
            prop="create_time"
            label="分账时间"
            align="center"
          />
          <el-table-column width="120px"
            prop="ledger_amount"
            label="分账金额"
            align="center"
          />
          <el-table-column prop="fail_reason" label="失败原因" align="center" />
          <!-- <el-table-column
            prop="ledger_channel"
            label="分账渠道"
            align="center"
            :formatter="ledgerChannelFormatter"
          ></el-table-column> -->
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ledgerFailList } from "@/api/bill.js";
export default {
  name: "ledgerErrorLog",

  data() {
    return {
      tableData: [],
      searchForm: {
        page: 1,
        per_page: 10000,
      },
      loading: false,
      dialogFormVisible: false,
    };
  },

  mounted() {},

  methods: {
    async isShow(ledgerId) {
      this.dialogFormVisible = true;
      this.searchForm.ledgerId = ledgerId;
      await this.initData();
    },
    async initData() {
      const { code, data } = await ledgerFailList(this.searchForm);
      if (code == 200) {
        this.tableData = data.data
      }
    },
    ledgerChannelFormatter(row) {
      switch (row.ledger_channel) {
        case 0:
          return "平台";
        case 1:
          return "商家";
      }
    },
    close() {
      this.dialogFormVisible = false;
      this.searchForm = {
        page: 1,
        per_page: 10000,
      };
    },
    handleClose(done) {
      done();
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.ledgerErrorLog {
  /deep/ .customW {
    border-radius: 10px !important;
    .el-dialog__header {
      border-bottom: 1px solid #e8eaec;
      .el-dialog__title {
        font-size: 14px;
        color: #17233d;
        font-weight: 700;
      }
    }
    .el-dialog__footer {
      border-top: 1px solid #e8eaec;
    }
  }
  .log-table {
    /deep/ .table_header {
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
    }
  }
}
</style>