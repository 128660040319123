<template>
  <div class="ledger">
    <div>
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="100px"
        size="small"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="一级商家">
              <el-select
                style="width: 100%"
                v-model="searchForm.seniorId"
                :remote-method="seniorRemoteMethod"
                :loading="seniorLoading"
                placeholder="请输入关键词"
                clearable
                filterable
                remote
                @clear="onSeniorClear"
              >
                <el-option
                  v-for="item in seniorList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="主体">
              <el-select
                style="width: 100%"
                v-model="searchForm.entityId"
                :remote-method="entityRemoteMethod"
                :loading="entityLoading"
                placeholder="请输入关键词"
                clearable
                filterable
                remote
                @clear="onEntityClear"
              >
                <el-option
                  v-for="item in entityList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="商品名称">
              <el-select
                style="width: 100%"
                v-model="searchForm.businessId"
                :remote-method="businessRemoteMethod"
                :loading="businessLoading"
                placeholder="请输入关键词"
                clearable
                filterable
                remote
                @clear="onBusinessClear"
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="类型">
              <el-select
                style="width: 100%"
                v-model="searchForm.ledgerChannel"
                @change="serchData"
              >
                <el-option
                  v-for="item in ledgerChannelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="还款时间">
              <el-date-picker
                style="width: 100%"
                v-model="create_date"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="setDateRange"
                @change="onCreateDateChange"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="分账时间">
              <el-date-picker
                style="width: 100%"
                v-model="ledger_date"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="setDateRange"
                @change="onLedgerDateChange"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="分账状态">
              <el-select
                style="width: 100%"
                v-model="searchForm.divideStatus"
                @change="serchData"
              >
                <el-option
                  v-for="item in divideStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="订单号">
              <el-input
                v-model="searchForm.billNo"
                placeholder="请输入订单号"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="学员">
              <el-input
                v-model="searchForm.username"
                placeholder="请输入学员名称"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="还款类型">
              <el-select
                style="width: 100%"
                v-model="searchForm.payment_mode"
                @change="serchData"
              >
                <el-option
                  v-for="item in payModeStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <div style="text-align: right">
            <el-button type="primary" size="small" @click="serchData"
              >查询</el-button
            >
            <el-button
              class="ml_20"
              size="small"
              @click="onExport"
              v-if="managerType != 0 || getAuth('exportLedger')"
              >导出</el-button
            >
          </div>
        </el-row>
      </el-form>
    </div>
    <base-table
      :columns="ledgerOrder"
      :data="tableData"
      :pagination="searchForm"
      :total="total"
      @getData="initData"
      :loading="loading"
      :isPaginationShow="true"
      wrapperHeight="calc(100% - 45px)"
    >
      <el-table-column
        width="150px"
        slot="bill_no"
        label="账单编号"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="checkDetail(scope.row)">{{
            scope.row.bill_no
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        slot="ledger_channel"
        label="类型"
        align="center"
        width="80"
        :formatter="ledgerChannelFormatter"
      ></el-table-column>
      <el-table-column
      slot="ledger_count"
      label="期数"
      align="center"
      width="80"
      :formatter="ledgerCountFormatter"
      >

      </el-table-column>
      <el-table-column
        slot="divide_status"
        label="分账状态"
        align="center"
        width="80"
        :formatter="divideStatusFormatter"
      ></el-table-column>
      <!-- <el-table-column
        slot="receipt_status"
        label="到账状态"
        align="center"
        width="80"
        :formatter="receiptStatusFormatter"
      ></el-table-column> -->
      <el-table-column
        slot="refund_type"
        label="还款类型"
        align="center"
        width="80"
        :formatter="refundTypeFormatter"
      ></el-table-column>
      <el-table-column
        slot="operation"
        label="操作"
        align="center"
        width="80"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="applyLedger(scope.row)"
            v-if="
              ((managerType != 0 || getAuth('applyLedger')) &&
                scope.row.divide_status == 0) ||
              scope.row.divide_status == 3
            "
            >分账</el-button
          >
          <el-button type="text" size="small" @click="onErrorLog(scope.row)"
            >失败记录</el-button
          >
        </template>
      </el-table-column>
    </base-table>
    <div
      v-loading.fullscreen.lock="fullscreenLoading"
      element-loading-text="正在申请分账中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    ></div>
    <ledger-error-log ref="ledgerErrorLog" />
  </div>
</template>

<script>
import columns from "./columns";
import { ledgerOrderList, applyLedger } from "@/api/bill.js";
import { selectBusiness, selectEntity, selectSenior } from "@/api/selectfun.js";
import {
  divideStatusList,
  ledgerChannelList,
} from "@/assets/equipment/allSelect.json";
import config from "@/utils/config.js";
import ledgerErrorLog from "./components/ledger-error-log.vue";
import { getAuth } from "@/utils/index.js";
export default {
  components: { ledgerErrorLog },
  data() {
    return {
      managerType: localStorage.getItem("managerType"),
      getAuth: getAuth,
      ...columns,
      businessLoading: false,
      businessList: [],
      entityLoading: false,
      entityList: [],
      seniorLoading: false,
      seniorList: [],
      divideStatusList: divideStatusList,
      ledgerChannelList: ledgerChannelList,
      ledger_date: "",
      create_date: "",
      tableData: [],
      payModeStatusList:[
        {
          value: 0,
          label: "当期支付"
        },
        {
          value: 1,
          label: "全部结清"
        },
        {
          value: 2,
          label: "部分结清"
        },
        {
          value: 3,
          label: "退课费结算"
        }
      ],
      searchForm: {
        page: 1,
        per_page: 10,
        divideStatus: -1,
        ledgerChannel: 0,
        startTime: "",
        endTime: "",
        businessId: "",
        entityId: "",
        seniorId: "",
        completeStartTime: "",
        completeEndTime: "",
      },
      total: 0,
      loading: false,
      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      fullscreenLoading: false,
    };
  },
  mounted() {
    this.initData();
    // this.getSelectBusiness();
    // this.getSelectEntity();
    // this.getSelectSenior();
  },
  computed: {},
  methods: {
    serchData() {
      this.searchForm.page = 1;
      this.searchForm.per_page = 10;
      this.initData();
    },
    async initData() {
      const { code, data } = await ledgerOrderList(this.searchForm);
      if (code == 200) {
        this.tableData = data.data;
        this.total = data.total;
      }
    },
    applyLedger(row) {
      let that = this;
      this.$confirm("是否对当前订单进行分账？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          that.fullscreenLoading = true;
          const parmes = {
            ledgerId: row.id,
          };
          const { code } = await applyLedger(parmes);
          if (code == 200) {
            setTimeout(() => {
              that.initData();
              this.$message({
                type: "success",
                message: "分账成功",
              });
              that.fullscreenLoading = false;
            }, 2000);
          } else {
            that.fullscreenLoading = false;
          }
        })
        .catch(() => {
          that.fullscreenLoading = false;
        });
    },
    onLedgerDateChange(val) {
      if (val) {
        this.searchForm.completeStartTime = val[0] + " " + "00:00:00";
        this.searchForm.completeEndTime = val[1] + " " + "23:59:59";
      } else {
        this.searchForm.completeStartTime = "";
        this.searchForm.completeEndTime = "";
      }
      this.initData();
    },
    onCreateDateChange(val) {
      if (val) {
        this.searchForm.startTime = val[0] + " " + "00:00:00";
        this.searchForm.endTime = val[1] + " " + "23:59:59";
      } else {
        this.searchForm.startTime = "";
        this.searchForm.endTime = "";
      }
      this.initData();
    },
    businessRemoteMethod(query) {
      if (query != "") {
        this.businessLoading = true;
        setTimeout(() => {
          this.businessLoading = false;
          this.getSelectBusiness(query);
        }, 200);
      } else {
        this.businessList = [];
      }
    },
    onBusinessClear() {
      this.searchForm.businessId = "";
      this.businessList = [];
      this.initData();
    },
    async getSelectBusiness(query) {
      const param = {
        business_name: query,
      };
      const { code, data } = await selectBusiness(param);
      if (code == 200) {
        this.businessList = data.map((item) => {
          item.value = item.business_id;
          item.label = item.full_name;
          return item;
        });
      }
    },
    entityRemoteMethod(query) {
      if (query != "") {
        this.entityLoading = true;
        setTimeout(() => {
          this.entityLoading = false;
          this.getSelectEntity(query);
        }, 200);
      } else {
        this.entityList = [];
      }
    },
    onEntityClear() {
      this.searchForm.entityId = "";
      this.entityList = [];
      this.initData();
    },
    async getSelectEntity(query) {
      const param = {
        entity_name: query,
      };
      const { code, data } = await selectEntity(param);
      if (code == 200) {
        this.entityList = data.map((item) => {
          item.value = item.entity_id;
          item.label = item.entity_name;
          return item;
        });
      }
    },
    seniorRemoteMethod(query) {
      if (query != "") {
        this.seniorLoading = true;
        setTimeout(() => {
          this.seniorLoading = false;
          this.getSelectSenior(query);
        }, 200);
      } else {
        this.seniorList = [];
      }
    },
    onSeniorClear() {
      this.searchForm.seniorId = "";
      this.seniorList = [];
      this.initData();
    },
    async getSelectSenior(query) {
      const param = {
        senior_name: query,
      };
      const { code, data } = await selectSenior(param);
      if (code == 200) {
        this.seniorList = data.map((item) => {
          item.value = item.senior_id;
          item.label = item.senior_name;
          return item;
        });
      }
    },
    checkDetail(row) {
      const routeData = this.$router.resolve({
        path: "/order/detail",
        query: {
          bill_no: row.bill_no,
        },
      });
      window.open(routeData.href, "_blank");
    },

    onErrorLog(row) {
      this.$refs.ledgerErrorLog.isShow(row.id);
    },

    onExport() {
      if (
        this.searchForm.startTime != "" ||
        this.searchForm.completeStartTime != ""
      ) {
        const url = `${
          config.BASE_URL
        }/export/ledgerOrder?token=${localStorage.getItem(
          "token"
        )}&divideStatus=${this.searchForm.divideStatus || ""}&ledgerChannel=${
          this.searchForm.ledgerChannel || ""
        }&startTime=${this.searchForm.startTime}&endTime=${
          this.searchForm.endTime
        }&businessId=${this.searchForm.businessId}&entityId=${
          this.searchForm.entityId
        }&seniorId=${this.searchForm.seniorId}&completeStartTime=${
          this.searchForm.completeStartTime
        }&completeEndTime=${
          this.searchForm.completeEndTime
        }&payment_mode=${this.searchForm.payment_mode}`;
        window.open(url, "_blank");
      } else {
        this.$message.error("还款时间或分账时间至少选择一个！");
      }
    },

    ledgerChannelFormatter(row) {
      switch (row.ledger_channel) {
        case 1:
          return "平台";
        case 2:
          return "商家";
      }
    },
    ledgerCountFormatter(row) {
      if (row.payment_mode == 0) {
        return (row.total_stage_count - row.remain_stage_count) + " / " + row.total_stage_count
      } else if (row.payment_mode == 1) {
        return row.total_stage_count + " / " + row.total_stage_count
      } else if(row.payment_mode == 2) {
        return 0
      } else {
        return ""
      }
    },
    refundTypeFormatter(row) {
      if (row.stage_type == 3) {
        return '快捷支付'
      }
      switch (row.payment_mode) {
        case 0:
          return "当期支付";
        case 1:
          return "全部结清";
        case 2:
          return "部分结清";
        case 3:
          return "退课费结算";
      }
    },
    divideStatusFormatter(row) {
      switch (row.divide_status) {
        case 0:
          return "待分账";
        case 1:
          return "分账中";
        case 2:
          return "分账成功";
        case 3:
          return "分账失败";
      }
    },
    // receiptStatusFormatter(row) {
    //   switch (row.receipt_status) {
    //     case 0:
    //       return "未打款";
    //     case 1:
    //       return "打款成功";
    //     case 2:
    //       return "打款失败";
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
</style>
